
import { PackageDialog, tools } from '@wenjuan/business'
import { mapState } from 'vuex'
export default {
  description: '用来统一入口文件，调用参数',
  name: 'CommonPackageDialog',
  components: {
    PackageDialog,
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapState(['packageDialog']),
    // 微应用弹窗
    microAppInfo() {
      return tools.$ENV.defineState()
    },
    // 以下是 拦截器，唯一入口配置属性变量
    /**
     * @attrs: activeVersion: 当前高亮版本
     */
    attrs() {
      const { on, ...attrs } = this.packageDialog
      const data = { ...attrs, ...this.$attrs }
      this.changeTryVersion(data)
      return data
    },
    listeners() {
      const { on } = this.packageDialog
      if (this.$listeners.input) Object.assign(on, this.$listeners.input)
      return on
    },
  },
  watch: {
    '$store.state.packageDialog.show'(val) {
      this.show = val
    },
  },
  methods: {
    // 监听关闭属性
    input() {
      this.$store.commit('setPackageDialog', {
        show: this.show,
      })
      const { on } = this.packageDialog
      if (on?.input) {
        on?.input()
      }
      this.$emit('input', this.show)
    },
    // 修改适用版的逻辑
    changeTryVersion(data) {
      const { isFreeTry, version } = this.microAppInfo
      if (isFreeTry) {
        data.activeVersion = version
      }
    },
  },
}
